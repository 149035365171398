import "core-js/modules/es.json.stringify.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.cumptedcolumns,
      rowKey: function rowKey(it, i) {
        return "".concat(JSON.stringify(it));
      },
      scroll: {
        x: 780
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.cumptedcolumns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "checktitle" ? _c("span", [record.title ? _c("span", [_vm._v(_vm._s(record.title))]) : _c("span", [_vm._v(" --- ")])]) : key == "imgurl" ? _c("span", [record.imgurl ? _c("img", {
            staticClass: "img_item",
            attrs: {
              src: record.imgurl,
              alt: ""
            },
            on: {
              click: function click($event) {
                return _vm.preview(record.imgurl);
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : key == "exampleurl" ? _c("span", [record.exampleurl ? _c("img", {
            staticClass: "img_item",
            attrs: {
              src: record.exampleurl,
              alt: ""
            },
            on: {
              click: function click($event) {
                return _vm.preview(record.exampleurl);
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : key == "dottedlineurl" ? _c("span", [record.dottedlineurl ? _c("img", {
            staticClass: "img_item",
            attrs: {
              src: record.dottedlineurl,
              alt: ""
            },
            on: {
              click: function click($event) {
                return _vm.preview(record.dottedlineurl);
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };