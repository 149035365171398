var columns = [{
  title: '类目名称',
  dataIndex: 'categoryname',
  key: 'categoryname',
  width: '10%',
  scopedSlots: {
    customRender: 'categoryname'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  width: '10%',
  scopedSlots: {
    customRender: 'brandname'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '5%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '鉴定点数量',
  dataIndex: 'checkcount',
  width: '7%',
  key: 'checkcount',
  scopedSlots: {
    customRender: 'checkcount'
  }
}, {
  title: '鉴定点名称',
  dataIndex: 'checktitle',
  key: 'checktitle',
  width: '10%',
  scopedSlots: {
    customRender: 'checktitle'
  }
}, {
  title: '鉴定点ICON',
  dataIndex: 'imgurl',
  key: 'imgurl',
  width: '10%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '鉴定点示例图',
  dataIndex: 'exampleurl',
  key: 'exampleurl',
  width: '10%',
  scopedSlots: {
    customRender: 'exampleurl'
  }
}, {
  title: '拍照引导图',
  dataIndex: 'dottedlineurl',
  key: 'dottedlineurl',
  width: '10%',
  scopedSlots: {
    customRender: 'dottedlineurl'
  }
}, {
  title: '鉴定点描述',
  dataIndex: 'common',
  key: 'common',
  width: '15%',
  scopedSlots: {
    customRender: 'common'
  },
  ellipsis: true
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  fixed: "right",
  width: '100',
  scopedSlots: {
    customRender: 'operation'
  }
}];
var aicolumns = [{
  title: 'AI类目名称',
  dataIndex: 'categoryname',
  key: 'categoryname',
  width: '8%',
  scopedSlots: {
    customRender: 'categoryname'
  }
}, {
  title: 'AI品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  width: '10%',
  scopedSlots: {
    customRender: 'brandname'
  }
}, {
  title: 'AI系列名称',
  dataIndex: 'seriesname',
  key: 'seriesname',
  width: '10%',
  scopedSlots: {
    customRender: 'seriesname'
  }
}, {
  title: '映射项',
  dataIndex: 'relationname',
  key: 'relationname',
  width: '10%',
  scopedSlots: {
    customRender: 'relationname'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '5%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '鉴定点名称',
  dataIndex: 'checktitle',
  key: 'checktitle',
  width: '7%',
  scopedSlots: {
    customRender: 'checktitle'
  }
}, {
  title: '鉴定点icon',
  dataIndex: 'imgurl',
  key: 'imgurl',
  width: '10%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '鉴定点示例图',
  dataIndex: 'exampleurl',
  key: 'exampleurl',
  width: '10%',
  scopedSlots: {
    customRender: 'exampleurl'
  }
}, {
  title: '拍照引导图',
  dataIndex: 'dottedlineurl',
  key: 'dottedlineurl',
  width: '10%',
  scopedSlots: {
    customRender: 'dottedlineurl'
  }
}, {
  title: '鉴定点描述',
  dataIndex: 'common',
  key: 'common',
  width: '15%',
  scopedSlots: {
    customRender: 'common'
  },
  ellipsis: true
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  fixed: "right",
  width: '100',
  scopedSlots: {
    customRender: 'operation'
  }
}];
export { columns, aicolumns };