var searchKeys = [{
  key: 'categoryid',
  label: '类目',
  placeholder: "请选择",
  cascader: true
}, {
  key: "brandid",
  label: "品牌",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var AIsearchKeys = [{
  key: "categoryid",
  label: "类目",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "brandid",
  label: "品牌",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, AIsearchKeys };