import _defineProperty from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import Mixin from "@/utils/mixin";
export default _defineProperty({
  mixins: [Mixin],
  props: {
    recordItems: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      previewVisible: false,
      previewImage: ""
    };
  },
  methods: {},
  computed: {
    dataSource: function dataSource() {
      return this.recordItems;
    },
    cumptedcolumns: function cumptedcolumns() {
      return [{
        title: '鉴定点名称',
        dataIndex: 'checktitle',
        key: 'checktitle',
        width: '10%',
        scopedSlots: {
          customRender: 'checktitle'
        }
      }, {
        title: '鉴定点icon',
        dataIndex: 'imgurl',
        key: 'imgurl',
        width: '10%',
        scopedSlots: {
          customRender: 'imgurl'
        }
      }, {
        title: '类目图片',
        dataIndex: 'exampleurl',
        key: 'exampleurl',
        width: '10%',
        scopedSlots: {
          customRender: 'exampleurl'
        }
      }, {
        title: '拍照引导图',
        dataIndex: 'dottedlineurl',
        key: 'dottedlineurl',
        width: '10%',
        scopedSlots: {
          customRender: 'dottedlineurl'
        }
      }, {
        title: '鉴定点描述',
        dataIndex: 'common',
        key: 'common',
        width: '15%',
        scopedSlots: {
          customRender: 'common'
        }
      }];
    }
  },
  created: function created() {}
}, "methods", {
  previewHandleCancel: function previewHandleCancel() {
    this.previewVisible = false;
  },
  preview: function preview() {
    var previewImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
    this.previewImage = previewImage;
    this.previewVisible = true;
  }
});